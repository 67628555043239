<template>
  <div class="myClass">
    <TopBar :type="2" :pageTitle="'我的班级'" />
    <!-- <div class="tab_list">
      <ul>
        <li :class="tabIndex==1?'active':''" @click="changeTab('1')"><p>班级信息</p></li>
        <li :class="tabIndex==2?'active':''" @click="changeTab('2')"><p>开放式班级</p></li>
      </ul>
    </div> -->
    <div class="class-list" v-if="tabIndex==1">
      <router-link :to="'/ldgxxyUserCenter/classInfo/'+item.id+'/'+'1'" class="class-info" v-for="item in classList" :key="item.id">
        <p class="class-title">{{item.name}}</p>
      </router-link>
      <div class="nodata" v-if="!classList">
        <img class="nodata-img" :src="require('@/assets/no-data.png')">
        <p class="nodata-text">暂无班级</p>
        <router-link :to="'/index'" class="back-index">返回首页</router-link>
      </div>
    </div>
    <div class="class-list" v-if="tabIndex==2">
      <router-link :to="'/userCenter/classInfo/'+item.id+'/'+'2'" class="class-info" v-for="item in openClassList" :key="item.id">
        <p class="class-title">{{item.name}}</p>
      </router-link>
      <div class="nodata" v-if="!openClassList">
        <img class="nodata-img" :src="require('@/assets/no-data.png')">
        <p class="nodata-text">暂无班级</p>
        <router-link :to="'/index'" class="back-index">返回首页</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'MyClass',
  data(){
    return {
      classList: null, //班级列表
      openClassList:null,//开放式班级列表
      tabIndex:1,
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getClassList(); //获取班级列表
    
  },
  methods: {
    changeTab(num){
      this.tabIndex =num
      if(this.tabIndex==1){
        this.getClassList
      } else{
        this.getOpenClassList()
      }
    },
    //获取班级列表
    getClassList(){
      this.$axios.post(`/v1/personal/classList`,{},{useLog:true}).then(res => {
        if(res.data.list.length > 0){
          this.classList = res.data.list;
        }else{
          this.classList = []
        }
      });
    },
    getOpenClassList(){
      this.$axios.post(`/v1/openClass/classList`,{},{useLog:true}).then(res => {
        if(res.data.list.length > 0){
          this.openClassList = res.data.list;
        }else{
          this.openClassList = []
        }
      });
    },
  },
  computed: {
   
  },
}
</script>

<style lang="scss" scoped>
// tab切换
.tab_list{
  ul{
    display: flex;
    li{
      flex: 1;
      text-align: center;
      line-height: 50px;
      p{
        display: inline;
        padding-bottom: 5px;
        font-size: 30px;
        border-bottom: 2px solid #fff;
      }
    }
    li.active{
      p{
        border-bottom: 2px solid #66b1ff;
        font-weight: bold;
      }
    }
  }
}
.class-list{
  padding: 28px 28px 0;
}
.class-info{
  width: 100%;
  height: 174px;
  background: url(~@/assets/class-bg.png) no-repeat;
  background-size: 100% auto;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  &::after{
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: url(~@/assets/arrows-white.png) no-repeat;
    background-size: 100% auto;
    transform: rotate(180deg);
    position: absolute;
    top: 50%;
    right: 28px;
    margin-top: -20px;
  }
}
.class-title{
  padding: 0 138px 0 40px;
  font-size: 28px;
  color: #FFF;
  line-height: 42px;
}
.nodata{
  width: 574px;
  padding-top: 200px;
  margin: 0 auto;
  text-align: center;
  .nodata-img{
    width: 100%;
    height: auto;
  }
  .nodata-text{
    font-size: 26px;
    color: #999;
    padding-top: 140px;
  }
  .back-index{
    display: inline-block;
    padding: 10px;
    margin: 48px auto 0;
    font-size: 28px;
    color: #999;
  }
}
</style>
